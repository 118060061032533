import React from "react";

const LazyMailing = React.lazy(() => import("./LazyMailing"));

const Mailing = () =>
  typeof window !== "undefined" && (
    <React.Suspense fallback={<div />}>
      <LazyMailing />
    </React.Suspense>
  );

export default Mailing;
