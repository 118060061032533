/* eslint-disable */
import defaultPriceRange from "./default-price-range";
const priceRange = [
  {
    ...defaultPriceRange[0],
    price: 900,
    getcourseInputId: "3565522",
  },
  {
    ...defaultPriceRange[1],
    price: 1800,
    getcourseInputId: "3565529",
  },
  {
    ...defaultPriceRange[2],
    price: 2500,
    getcourseInputId: "3565535",
  },
  {
    ...defaultPriceRange[3],
    price: 3500,
    getcourseInputId: "3565544",
  },
];
export default {
  UIExplanations: [
    {
      id: "ea3c9960-f880-5d8e-af95-d98b1f86359a",
      explanationsImage: {
        src: "/images/graphic-system.jpg",
      },
      explanationsTitle: "Что такое графсистема?",
      explanationsTextTitle:
        "Комбинация элементов и принципов по определённым правилам.",
      explanationsTextDescription:
        "Графсистема строится на правилах дизайна элементов и принципах вёрстки. Усиливает насмотренность дизайнера.",
    },
    {
      id: "c7878326-2114-5e39-9a7a-15be0e3fa7ef",
      explanationsImage: {
        src: "/images/ui/explanations/02.jpg",
      },
      explanationsTitle: "Что такое графсистема UI‑дизайн?",
      explanationsTextTitle: "Пользовательский интерфейс (User‑Interface)",
      explanationsTextDescription:
        "В отличие от UX, в котором мы проектируем логику, UI отвечает за визуальную составляющую интерфейсов. Окна, плашки, кнопки и вот это вот всё. То, ради чего строятся дизайн-системы. Изучая UI мы подойдём к нему как графические дизайнеры — нарушим границы интерактивных правил. Поймём, что над современными интерфейсами можно шутить и иронизировать, переводя их в плоскость статичных макетов графдизайна. Это отдушина для тех, кто занимается серьёзными диджитал-продуктами.",
    },
  ],
  priceRange,
};
