import React from "react";
import { Container } from "components/style";
import * as SC from "./style";

const MasterClassStream = ({
  title,
  description,
  youtubeUrl,
  marginBottom,
}) => (
  <SC.Section marginBottom={marginBottom}>
    <Container>
      <SC.Wrapper>
        <SC.VideoSection>
          <SC.VideoSectionContent>
            {title && (
              <SC.VideoSectionTitle>{title}</SC.VideoSectionTitle>
            )}
            <SC.VideoSectionDescr>{description}</SC.VideoSectionDescr>
          </SC.VideoSectionContent>
          <SC.YoutubeBlock>
            <div className="loadingio-spinner-rolling-ta5f1nqy5p">
              <div className="ldio-6b142d04hhl">
                <div />
              </div>
            </div>
            <iframe
              title="youtubeFrame"
              width="100%"
              src={youtubeUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </SC.YoutubeBlock>
        </SC.VideoSection>
      </SC.Wrapper>
    </Container>
  </SC.Section>
);

export default MasterClassStream;
