// react/gatsby imports
import React from "react";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import CourseExplanations from "components/pages/course-page/explanations";
import CoursePriceRange from "components/pages/course-page/price-range";
import EducationQuestions from "components/pages/course-page/education-questions";
import Mailing from "components/common/mailing";
import MasterClassPortfolio from "components/pages/course-page/portfolio/MasterClassPortfolio";
import SchoolSystem from "components/pages/course-page/school-system";
import MasterClassStream from "components/pages/course-page/master-class-stream";
import PaymentChoices from "components/pages/payment-choices";
import MasterClassTemplateOffer from "components/pages/course-page/offer/master-class";
// assets
import ogImage from "assets/images/seo/ui.jpg";
import ogImageVK from "assets/images/seo/vk/ui.jpg";
// data
import localData from "data/pages/ui";
// hooks
import useUIPortfolio from "hooks/portfolio/useUIPortfolio";
import useUIQuery from "hooks/graphql/useUIQuery";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";

// Для копирования: "UI‑дизайн" с неразрывным тире

const UIPage = () => {
  const prismicData = useUIQuery();
  const portfolio = useUIPortfolio();
  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};
  return (
    <Layout logo="ui">
      <div className="section-top-block" />
      <SEO
        title="Графсистема UI"
        description={
          seoData?.seodescription?.text ||
          "User Interface — визуальная составляющая интерфейсов. Окна, плашки, кнопки и вот это вот всё."
        }
        keywords={["ui", "графсистема", "гранич", "granich"]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.graphicSystemUI)}
      />
      <MasterClassTemplateOffer
        courseName="UI"
        data={data}
        courseDescr="User Interface — визуальная составляющая интерфейсов. Окна, плашки, кнопки и вот это вот всё."
      />
      <CourseExplanations twoRows data={localData.UIExplanations} />
      <SchoolSystem />
      <MasterClassPortfolio
        courseTitle={data.coursetitle?.text}
        pinterestText={"UI‑дизайн в Энциклопедии\nГрафдизайна Школы"}
        pinterestLink="https://www.pinterest.ru/vadim_granich/ui/"
        portfolio={portfolio}
        graphicSystemName="UI‑дизайн"
      />
      <div id="participation-section" />
      {data.coursestatus ? (
        <CoursePriceRange
          formId="ltForm3153227"
          priceRange={localData.priceRange}
          courseTitle={data.coursetitle?.text}
          formPostUrl="https://school.granich.design/pl/lite/block-public/process-html?id=1462408390"
        />
      ) : (
        <Mailing />
      )}
      <PaymentChoices courseType="Мастер-класс" />
      <EducationQuestions />
      <MasterClassStream
        title="Урок по системе UI‑дизайн"
        description="По графсистеме UI есть урок на ютубе Школы. В нём я точечно разбираю построение объёмных матовых UI‑плашек. Урок идёт как полезное дополнение к этому мастер‑классу."
        youtubeUrl="https://www.youtube.com/embed/Iy0s7W-S2EY"
      />
    </Layout>
  );
};

export default UIPage;
