const PRICE_RANGE = {
  pdfPresentation: {
    title: "PDF-презентация",
    description:
      " Наглядная презентация, в которой бережно разжёвано всё главное, что вам следует знать о графсистеме",
  },
  courseVideo: {
    title: "Видеолекция",
    description:
      "Видеозапись разбора презентации. Для тех, кто хочет слушать и получать дополнительные комментарии по слайдам",
  },
  homework: {
    title: "Домашнее задание",
    description:
      "Домашнее задание поможет закрепить на практике понимание графсистемы",
  },
  individualReview: {
    title: "Индивидуальный разбор ДЗ",
    description:
      "Куратор запишет для вас видеоразбор ДЗ, где\r\nпоможет определить ошибки и даст рекомендации",
  },
  portfolio: {
    title: "Макет в портфолио",
    description:
      "Куратор поможет создать макет с нуля\r\nпо графсистеме для вашего портфолио",
  },
  freeMaterialsPresentation: {
    title: "Презентация лучших бесплатных материалов",
    description:
      "Для более комфортного создания макета, вы получите презентацию с перечислением площадок с лучшими бесплатными материалами для графдизайна",
  },
};

module.exports = [
  {
    pricesTitle: "Презентация",
    priceConditions: [
      {
        text: PRICE_RANGE.pdfPresentation.title,
        isActive: true,
        description: PRICE_RANGE.pdfPresentation.description,
      },
      {
        text: PRICE_RANGE.courseVideo.title,
        isActive: false,
        description: PRICE_RANGE.courseVideo.description,
      },
      {
        text: PRICE_RANGE.homework.title,
        isActive: false,
        description: PRICE_RANGE.homework.description,
      },
      {
        text: PRICE_RANGE.individualReview.title,
        isActive: false,
        description: PRICE_RANGE.individualReview.description,
      },
      {
        text: PRICE_RANGE.portfolio.title,
        isActive: false,
        description: PRICE_RANGE.portfolio.description,
      },
      {
        text: PRICE_RANGE.freeMaterialsPresentation.title,
        isActive: false,
        description: PRICE_RANGE.freeMaterialsPresentation.description,
      },
    ],
    price: 900,
  },
  {
    pricesTitle: "Видеоформат",
    priceConditions: [
      {
        text: PRICE_RANGE.pdfPresentation.title,
        isActive: true,
        description: PRICE_RANGE.pdfPresentation.description,
      },
      {
        text: PRICE_RANGE.courseVideo.title,
        isActive: true,
        description: PRICE_RANGE.courseVideo.description,
      },
      {
        text: PRICE_RANGE.homework.title,
        isActive: false,
        description: PRICE_RANGE.homework.description,
      },
      {
        text: PRICE_RANGE.individualReview.title,
        isActive: false,
        description: PRICE_RANGE.individualReview.description,
      },
      {
        text: PRICE_RANGE.portfolio.title,
        isActive: false,
        description: PRICE_RANGE.portfolio.description,
      },
      {
        text: PRICE_RANGE.freeMaterialsPresentation.title,
        isActive: false,
        description: PRICE_RANGE.freeMaterialsPresentation.description,
      },
    ],
    price: 1800,
  },
  {
    pricesTitle: "C заданием",
    priceConditions: [
      {
        text: PRICE_RANGE.pdfPresentation.title,
        isActive: true,
        description: PRICE_RANGE.pdfPresentation.description,
      },
      {
        text: PRICE_RANGE.courseVideo.title,
        isActive: true,
        description: PRICE_RANGE.courseVideo.description,
      },
      {
        text: PRICE_RANGE.homework.title,
        isActive: true,
        description: PRICE_RANGE.homework.description,
      },
      {
        text: PRICE_RANGE.individualReview.title,
        isActive: true,
        description: PRICE_RANGE.individualReview.description,
      },
      {
        text: PRICE_RANGE.portfolio.title,
        isActive: false,
        description: PRICE_RANGE.portfolio.description,
      },
      {
        text: PRICE_RANGE.freeMaterialsPresentation.title,
        isActive: false,
        description: PRICE_RANGE.freeMaterialsPresentation.description,
      },
    ],
    price: 2500,
  },
  {
    pricesTitle: "Макет с нуля",
    priceConditions: [
      {
        text: PRICE_RANGE.pdfPresentation.title,
        isActive: true,
        description: PRICE_RANGE.pdfPresentation.description,
      },
      {
        text: PRICE_RANGE.courseVideo.title,
        isActive: true,
        description: PRICE_RANGE.courseVideo.description,
      },
      {
        text: PRICE_RANGE.homework.title,
        isActive: true,
        description: PRICE_RANGE.homework.description,
      },
      {
        text: PRICE_RANGE.individualReview.title,
        isActive: true,
        description: PRICE_RANGE.individualReview.description,
      },
      {
        text: PRICE_RANGE.portfolio.title,
        isActive: true,
        description: PRICE_RANGE.portfolio.description,
      },
      {
        text: PRICE_RANGE.freeMaterialsPresentation.title,
        isActive: true,
        description: PRICE_RANGE.freeMaterialsPresentation.description,
      },
    ],
    price: 3500,
  },
];
